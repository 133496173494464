<!-- 学生成长-我的学生 -->
<template>
    <div class="center-main">
        <div v-if="!errorShow" class="right-panel">
            <loading :loadingShow="loadingShow"></loading>
            <div v-if="!loadingShow" class="content-wrapper">
                <ExpandFilter :formData="formData" @clickBtn="clickBtn"
                              @changeBtnFormType="changeBtnFormType"></ExpandFilter>
                <div class="operation-btn-view">
                    <div>
                        <!-- <xk-button type="primary" text @click="selAll">全选</xk-button>
                        <xk-button type="primary" text @click="counterSel">反选</xk-button>
                        <xk-button type="danger" text @click="cleanSel">清除</xk-button> -->
                        <el-dropdown trigger="click" style="margin-left: 32px">
                            <xk-button type="warning" v-hasPermi="['myStudentBase:batchOper']">
                                批量操作<i class="el-icon-arrow-down el-icon--right"></i>
                            </xk-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item>
                                    <el-button type="primary" plain size="mini" style="width: 100%" @click="acts(1)">
                                        在校
                                    </el-button>
                                </el-dropdown-item>
                                <el-dropdown-item>
                                    <el-button type="danger" plain size="mini" style="width: 100%;margin-top: 8px"
                                               @click="acts(2)">毕业
                                    </el-button>
                                </el-dropdown-item>
                                <el-dropdown-item>
                                    <el-button type="warning" plain size="mini" style="width: 100%;margin-top: 8px"
                                               @click="acts(3)">转学
                                    </el-button>
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                    <div class="btn-right" @click="exp" v-hasPermi="['myStudentBase:export']">
                        <img :src="exportUrl" alt="export" class="export">
                        <xk-button  type="primary" text>导出</xk-button>
                    </div>
                </div>
                <table-data
                    v-loading="loadingTable"
                    id="table"
                    ref="table"
                    :config="table_config"
                    :tableData="table_data"
                    :backgroundHeader="backgroundHeader"
                    :headerColor="headerColor"
                    @handleSelectionChange="handleSelectionChange">
                    <template v-slot:operation="slotData">
                        <!-- <xk-button type="primary" v-hasPermi="['myStudentBase:document']" size="mini" @click="addc(slotData.data)">学生档案
                        </xk-button> -->
                        <xk-button v-hasPermi="['myStudentBase:edit']" type="success" size="mini" @click="edit(slotData.data)">编辑
                        </xk-button>
                    </template>
                </table-data>
                <pagination :total="total" :page.sync="listQuery.pageNum" :limit.sync="listQuery.pageRow"
                            @pagination="getList"/>

                <dialog-wrapper :dialogObj="dialogObj" @handleClose="handleClose">
                    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-position="right" label-width="120px">
                        <div class="lt">
                            <!--              <el-form-item label="学生头像" prop="stuImg">-->
                            <!--                <upload v-model="ruleForm.stuImg"></upload>-->
                            <!--              </el-form-item>-->
                            <label class="stu-info">学生头像</label>
                            <upload v-model="ruleForm.stuImg" :positionStatus="true"></upload>
                        </div>
                        <div class="rt">
                            <el-form-item label="学生姓名" prop="studentName">
                                <el-input v-model="ruleForm.studentName"></el-input>
                            </el-form-item>
                            <el-form-item label="学号" prop="studyNo">
                                <el-input v-model="ruleForm.studyNo"></el-input>
                            </el-form-item>
                            <el-form-item label="性别" prop="sex">
                                <el-radio v-model="ruleForm.sex" label="1">男</el-radio>
                                <el-radio v-model="ruleForm.sex" label="2">女</el-radio>
                            </el-form-item>
                            <el-form-item label="身份证号" prop="idCard">
                                <el-input v-model="ruleForm.idCard"></el-input>
                            </el-form-item>
                        </div>
                        <div class="tac">
                            <xk-button type="primary" @click="submitForm('ruleForm')">
                                确定
                            </xk-button>
                            <xk-button type="info" style="margin-left: 24px" @click="resetForm('ruleForm')">取消
                            </xk-button>
                        </div>
                    </el-form>
                </dialog-wrapper>
            </div>
        </div>
        <error :errorShow="errorShow"></error>
    </div>
</template>

<script>
import {
    // 业务组件
    FilterData,
    TableData,
    DialogWrapper,
    Pagination,
    // 功能组件
    Error,
    Loading,
    // 工具函数
    throttle, debounce
} from 'common-local';

import {
    mapState
} from 'vuex';

// 自定义颜色
import upload from './Summary/upload.vue'
import {downloadFile} from "@/libs/utils";
import ExpandFilter from "./Sub/ExpandFilter";

export default {
    components: {
        FilterData,
        TableData,
        DialogWrapper,
        // 功能组件
        Error,
        Loading,
        Pagination,
        upload,
        ExpandFilter
    },
    data() {
        return {
            listQuery: {
                //id: '',
                studentName: '',
                schoolId: '',
                organId: '',
                pageNum: 1,
                pageRow: 20,
                status: '',
                studyNo: '',
                idCard: '',
                sex: '',
                teacherId: ''
            },
            total: 0,
            // 头部筛选
            formData: {
                btnFormType: true,
                formInline: {
                    studentName: '',
                },
                data: [
                    {
                        type: 'select',
                        label: '班级',
                        value: '',
                        placeholder: '请选择班级',
                        key: 'organId',
                        list: []
                    },
                    {
                        type: 'input',
                        label: '学生姓名',
                        value: '',
                        placeholder: '请输入学生姓名',
                        key: 'studentName'
                    },
                    {
                        type: 'input',
                        label: '身份证号',
                        value: '',
                        placeholder: '请输入身份证号',
                        key: 'idCard'
                    },
                    {
                        type: 'input',
                        label: '学号',
                        value: '',
                        placeholder: '请输入学号',
                        key: 'studyNo'
                    },
                    {
                        type: 'select',
                        label: '性别',
                        value: '',
                        placeholder: '请选择性别',
                        key: 'sex',
                        list: [{
                            label: '男',
                            value: 1
                        },
                            {
                                label: '女',
                                value: 2
                            }
                        ]
                    },
                ],
                btnList: [{
                    type: "enquiry",
                    text: '查询',
                    fn: 'primary',
                    auth:['myStudentBase:list']
                },
                    {
                        type: "enquiry",
                        text: '重置',
                        fn: 'reset'
                    }
                ]
            },

            // 表格配置
            table_config: {
                thead: [{
                    label: "头像",
                    labelWidth: 60,
                    type: 'images',
                    prop: "stuImg"
                },
                    {
                        label: "学生姓名",
                        prop: "studentName",
                    },
                    {
                        label: "性别",
                        prop: "sex",
                        type: 'function',
                        callBack(row) {
                            return {
                                1: '男',
                                2: '女'
                            } [+row.sex]
                        }
                    },
                    {
                        label: "学号",
                        prop: "studyNo"
                    },
                    {
                        label: "身份证号",
                        prop: "idCard",
                        type:'popoverText',
                        labelWidth: '190'
                    },
                    {
                        label: "状态",
                        prop: "status",
                        type: 'function',
                        callBack(row) {
                            return {
                                1: '在校',
                                2: '毕业',
                                3: '转学'
                            } [row.status]
                        }
                    },
                    {
                        label: "操作",
                        type: "slot",
                        labelWidth: "250",
                        slotName: 'operation'
                    }
                ],
                check: true,
                height: ''
            },
            // 表格数据
            table_data: [],

            // 弹出框配置
            dialogObj: {
                title: '添加组织',
                dialogVisible: false,
            },
            // 功能组件
            errorShow: false,
            loadingShow: false,
            loadingTable: true,

            template_info: [],
            // 表头
            backgroundHeader: '#FAFBFC',
            // 表头字体
            headerColor: '#595959',
            // 新增
            ruleForm: {
                studentName: '',
                remark: '',
                id: '',
                schoolId: '',
                idCard: '',
                studyNo: '',
                status: 1,
                sex: '',
                classType: 1,
                teacherId: ''
            },
            rules: {
                studentName: [{
                    required: true,
                    message: '请输入学生姓名',
                    trigger: ['change', 'blur']
                }],
                sex: [{
                    required: true,
                    message: '请选择性别',
                    trigger: ['change', 'blur']
                }],
                studyNo: [{
                    required: true,
                    message: '请输入学号',
                    trigger: ['change', 'blur']
                }, {
                    validator: (rule, value, callback) => {
                        if (!/^[A-Za-z0-9]+$/.test(value)) {
                            callback('学号只能包含字母或数字');
                        } else {
                            callback();
                        }
                    }, trigger: 'blur'
                }],
                idCard: [{
                    required: true,
                    message: '请输入身份证号',
                    trigger: ['change', 'blur']
                }, {
                    validator: (rule, value, callback) => {
                        if (!/^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(value)) {
                            callback('身份证号不正确');
                        } else {
                            callback();
                        }
                    }, trigger: 'blur'
                }],
            },
            pageType: 'add',
            tableListSel: [],
        }
    },
    computed: {
        ...mapState({
            templateInfo: state => state.templateInfo,
            teacherId: state => state.teacherId,
        }),
        exportUrl() {
            return require('../../assets/images/export.png')
        }
    },
    created() {
        this.ruleForm.schoolId = this.listQuery.schoolId = this.$store.state.schoolId
        this.listQuery.teacherId = this.$store.state.teacherId
        this.getList();
        this.getClassList()
    },
    mounted() {
        this.table_config.height = document.body.clientHeight - document.getElementById('table').offsetTop - 62
        window.onresize = debounce(() => {
            if (!document.getElementById('table')) return
            const height = document.body.clientHeight - document.getElementById('table').offsetTop - 62
            this.table_config.height = height.toString()
        }, 200)
    },
    methods: {
        /*
         * 表头颜色
         */
        initTabColor() {
            this.template_info = this.templateInfo;
            this.template_info.forEach((p, i) => {
                if (p.sel) {
                    this.headerColor = p.textColor;
                }
            })
        },
        getClassList() {
            this._fet('/school/schoolOrgan/getClassList', {
                schoolId: this.listQuery.schoolId
            }).then((res) => {
                this.handleRes(res, () => {
                    this.formData.data[0].list = res.data.data.map(i => ({label: i.name, value: i.id}))
                })
            })
        },
        /*
         *   初始化表格
         */
        getList(t) {
            if (t === 1) {
                this.listQuery.pageNum = 1
            }
            this.loadingTable = true
            this._fet('/school/schoolStudent/myStudent', this.listQuery).then((res) => {
                this.handleRes(res, () => {
                    this.table_data = res.data.data.list
                    this.total = res.data.data.totalCount
                    this.loadingTable = false
                })
            })
        },

        /*
         *   功能按钮
         *   selAll 全选
         *   counterSel 反选
         *   cleanSel 清除
         *   add 新增
         *   importAll 导出
         */
        handleSelectionChange(data) {
            this.tableListSel = data;
        },
        selAll() {
            let ref = this.$refs['table'].$refs.multipleTable;
            // ResourcingOpenOperations.selAll(this.table_data, ref)
            this.table_data.forEach(function (element, index) {
                ref.toggleRowSelection(element, true)
            });
        },
        counterSel() {
            let ref = this.$refs['table'].$refs.multipleTable;
            this.tableListSel = this.counter(this.table_data, this.tableListSel, ref);
        },
        counter(data, selData, ref) {
            let arr = [];
            let arr2 = [];
            data.forEach(function (element, index) {
                const find = selData.find(it => it.id == element.id)
                if (find) {
                    arr.push(find)
                } else {
                    arr2.push(element)
                }
            });
            this.selAll(data, ref)
            arr.forEach(function (element, index) {
                ref.toggleRowSelection(element, false)
            });
            return arr2;

        },
        cleanSel() {
            let ref = this.$refs['table'].$refs.multipleTable;
            this.tableListSel = []
            this.table_data.forEach(function (element, index) {
                ref.clearSelection();
            });
        },
        importAll() {
            this.$router.push('/deviceManagementImport')
        },
        exp() {
            let obj = {
                teacherId: this.teacherId
            }
            let filter = this.formData.data.filter(i => i.value !== '')
            if (this.tableListSel.length > 0) {
                obj.ids = this.tableListSel.map(i => i.id)
                obj.identification = 2
            } else {
                if (filter.length === 0) {
                    obj.identification = 3
                } else {
                    obj.schoolId = this.listQuery.schoolId
                    obj.identification = 1
                    filter.forEach(item => obj[item.key] = item.value)
                }
            }
            downloadFile({url: '/school/schoolStudent/export', form: obj}, () => {
            }, () => {

            })
        },
        acts(data) {
            if (this.tableListSel.length === 0) {
                return this.$message.warning('未选择人员信息！')
            }
            this.$confirm(`确定要改为${data === 1 ? '在校' : (data === 2 ? '毕业' : '转学')}状态吗?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                // .map(it => it.id)
                this._fet('/school/schoolStudent/updateStatus', {list: this.tableListSel, status: data}).then(r => {
                    if (r.data.code == 200) {
                        this.$message.success('操作成功')
                        this.getList()
                    } else {
                        this.$message.error(r.data.msg)
                    }
                })
            }).catch(() => {
            })
        },

        nodeCLick(data) {
            if (data.organType == 4) {
                this.listQuery.organId = this.ruleForm.organId = data.id
                this.getList()
            }
        },

        /*
         *       clickBtn 表格筛选
         *    edit表格编辑
         *
         * */
        clickBtn(type) {
            switch (type.item.fn) {
                case 'primary': // 查询
                    this.formData.data.forEach((item) => {
                        this.listQuery[item.key] = item.value;
                    })
                    this.getList(1);
                    break;
                case 'reset': // 重置
                    this.formData.data.forEach((item) => {
                        item.value = '';
                        this.listQuery[item.key] = ''
                    })
                    this.getList(1);
                    break;
            }
        },

        add() {
            this.pageType = 'add';
            this.dialogObj.title = "添加";
            this.resetData();
            this.dialogObj.dialogVisible = true;
        },
        edit(id) {
            this.pageType = 'edit';
            this.dialogObj.title = "编辑";
            Object.assign(this.ruleForm, id)
            this.dialogObj.dialogVisible = true;
        },

        handleClose() {
            this.dialogObj.dialogVisible = false;
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.addNewSector(this.ruleForm);
                } else {
                    return false;
                }
            });
        },

        addNewSector(ruleForm) {
            this._fet('/school/schoolStudent/save', ruleForm).then((res) => {
                if (res.data.code == '200') {
                    this.resetData();
                    this.getList();
                    this.$message.success('成功')
                    this.handleClose();
                } else {
                    //throw new Error('添加部门接口请求失败，联系后端')
                    this.$message.error('添加失败')
                }
            })
        },

        addc() {
            this.$router.push('/doc')
        },
        // 新增取消
        resetForm(formName) {
            this.$refs[formName].resetFields();
            this.handleClose();
        },

        resetData() {
            this.ruleForm = {
                studentName: '',
                remark: '',
                id: '',
                schoolId: this.ruleForm.schoolId,
                idCard: '',
                studyNo: '',
                organId: this.ruleForm.organId,
                status: 1,
                classType: 1
            };
        },
        changeBtnFormType() {
            this.formData.btnFormType = !this.formData.btnFormType;
            this.$nextTick(() => {
                this.table_config.height = document.body.clientHeight - document.getElementById('table').offsetTop - 62
            })
        },
        // 接口请求结果处理
        handleRes(res, fn) {
            if (res.data.code === '200') {
                fn()
            } else if (res.data && res.data.msg) {
                this.$message.error(res.data.msg)
            }
        },
    },
    watch: {
        'templateInfo': {
            handler() {
                this.initTabColor();
            },
            deep: true,
            immediate: true
        }
    }
}
</script>

<style lang="scss" scoped>

.w2 {
    width: 50%;
    display: inline-block;
}

.lt {
    float: left;
    width: 30%;
}

.rt {
    width: 70%;
    float: left;
}

.tac {
    text-align: center;
}

.operation-btn-view {
    box-sizing: border-box;
    background-color: #ffffff;
    margin-top: 10px;
    height: 60px;
    padding: 0 30px 0 8px;
    line-height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

// .btn-left {
//   float: left;
// }

// .btn-right {
//   float: right;
//   display: flex;
//   align-items: center;
//   cursor: pointer;
// }

.export {
    width: 12px;
    height: 12px;
    margin-right: -8px;
}

.el-dropdown-menu {
    margin-top: 0;
    width: 111px;
}

.el-dropdown-menu__item {
    padding: 0 10px;

    &:hover {
        background-color: #fff;
    }
}

.el-dialog__wrapper {
    width: auto;
    overflow: hidden;
}

.stu-info {
    margin-left: 30px;
    text-align: center;
}

</style>
